<template>
    <div class="content-area__body">
        <!-- counter info -->
        <info-counter></info-counter>

        <!-- user list -->
        <recent-application></recent-application>

        <!-- analytics info -->
        <analytics v-if="showAnalytics"></analytics>

        <!-- data table -->
        <recent-jobs></recent-jobs>
    </div>
</template>
<script>
const InfoCounter = () =>  import("./InfoCounter");
const RecentApplication = () =>  import("./RecentApplication");
const RecentJobs = () =>  import("./RecentJobs");
const Analytics = () =>  import("./Analytics");

import {mapState} from "vuex";

export default {
    components: {
        RecentApplication,
        RecentJobs,
        InfoCounter,
        Analytics
    },
    computed: {
        ...mapState(['user']),
        showAnalytics() {
            return this.user.package_rules.analytics === 1;
        }
    }
}
</script>
